import React from "react"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const References = ({ intl }) => (
  <Layout>
    <SEO lang={intl.locale} title={intl.formatMessage({ id: "references.title" })} />
    <h1>
      <FormattedMessage id="references.title" />
    </h1>

    <h3>
      <FormattedMessage id="references.clients" />
    </h3>
    <p>
      <FormattedMessage id="references.clients_intro" />
    </p>

    <ul>
      <li>
        <FormattedMessage id="references.client_risesmart" />
      </li>
      <li>
        <FormattedMessage id="references.client_cochhub" />
      </li>
      <li>
        <FormattedMessage id="references.client_c4s" />
      </li>
      <li>
        <FormattedMessage id="references.client_xfel" />
      </li>
      <li>
        <FormattedMessage id="references.client_academy" />
      </li>
      <li>
        <FormattedMessage id="references.client_lobraco" />
      </li>
      <li>
        <FormattedMessage id="references.client_gaia" />
      </li>
    </ul>

  </Layout>
)

export default injectIntl(References)
